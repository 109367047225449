import React, { Component } from 'react';

import BaseLayout from '../components/Layouts/BaseLayout';
import Blocks from '../components/Blocks/Blocks';

export default class PageTemplate extends Component {
    render() {
        const { page } = this.props.data;
        return (
            <BaseLayout>
                <div dangerouslySetInnerHTML={{ __html: page.content }} />
                {page && page.acf && page.acf.page_layout_page &&
                    <Blocks layouts={page.acf.page_layout_page} />
                }
            </BaseLayout>
        );
    }
}

export const query = graphql`
    query currentPage($id: String!) {
        page: wordpressPage(id: { eq: $id }) {
            title
            content
            acf {
              page_layout_page {
                __typename
                ... on WordPressAcf_text_block {
                  title
                  text
                }
                ... on WordPressAcf_image_block {
                image {
                    title
                    source_url
                    # localFile {
                    #   childImageSharp {
                    #     sizes(maxWidth: 900, maxHeight: 520, quality: 90) {
                    #     	...GatsbyImageSharpSizes
                    #     }
                    #   }
                    # }
                  }
                }
              }
            }
        }
    }
`